export const DriverSync: any = [
    {
        id: 'R1',
        routeName: 'Downtown Express',
        currentStop: 'TechCore Solutions',
        lastLocation: 'Global Finance Hub',
        nextLocation: 'Quantum Industries',
        status: 'on-time',
        driverId: 'D1',
        currentLocation: [40.7128, -74.0060],
        stops: [
            {
                id: 'S1-1',
                sequence: 1,
                name: 'Global Finance Hub',
                address: '123 Wall Street, Financial District',
                status: 'completed',
                estimatedTime: '09:00',
                actualTime: '09:02',
                remarks: 'Delivery completed at reception',
                coordinates: [40.7075, -74.0021]
            },
            {
                id: 'S1-2',
                sequence: 2,
                name: 'TechCore Solutions',
                address: '456 Innovation Ave, Tech District',
                status: 'in-progress',
                estimatedTime: '09:30',
                actualTime: '09:35',
                remarks: 'Loading dock entrance',
                coordinates: [40.7128, -74.0060]
            },
            {
                id: 'S1-3',
                sequence: 3,
                name: 'Quantum Industries',
                address: '789 Future Lane, Research Park',
                status: 'pending',
                estimatedTime: '10:00',
                remarks: 'Requires security clearance',
                coordinates: [40.7589, -73.9851]
            },
            {
                id: 'S1-4',
                sequence: 4,
                name: 'Digital Dynamics',
                address: '321 Silicon Street, Tech Hub',
                status: 'pending',
                estimatedTime: '10:30',
                remarks: 'Access code required',
                coordinates: [40.7420, -73.9890]
            },
            {
                id: 'S1-5',
                sequence: 5,
                name: 'Cyber Systems Inc',
                address: '654 Network Drive, Data District',
                status: 'pending',
                estimatedTime: '11:00',
                remarks: 'Server room delivery',
                coordinates: [40.7320, -73.9950]
            },
            {
                id: 'S1-6',
                sequence: 6,
                name: 'Innovation Labs',
                address: '987 Research Blvd, Tech Valley',
                status: 'pending',
                estimatedTime: '11:30',
                remarks: 'Fragile equipment',
                coordinates: [40.7220, -74.0010]
            }
        ]
    },
    {
        id: 'R2',
        routeName: 'Suburban Circuit',
        currentStop: 'Evergreen Markets',
        lastLocation: 'Retail Paradise Mall',
        nextLocation: 'Sunset Gardens Co.',
        status: 'late',
        driverId: 'D2',
        currentLocation: [40.7829, -73.9654],
        stops: [
            {
                id: 'S2-1',
                sequence: 1,
                name: 'Retail Paradise Mall',
                address: '101 Shopping Center Blvd, Retail District',
                status: 'completed',
                estimatedTime: '09:15',
                actualTime: '09:30',
                remarks: 'Delivery at loading dock B',
                coordinates: [40.7549, -73.9840]
            },
            {
                id: 'S2-2',
                sequence: 2,
                name: 'Evergreen Markets',
                address: '202 Fresh Produce Way, Market District',
                status: 'in-progress',
                estimatedTime: '09:45',
                actualTime: '10:05',
                remarks: 'Temperature-controlled items',
                coordinates: [40.7829, -73.9654]
            },
            {
                id: 'S2-3',
                sequence: 3,
                name: 'Sunset Gardens Co.',
                address: '303 Green Valley Road, Garden District',
                status: 'pending',
                estimatedTime: '10:15',
                remarks: 'Special handling required',
                coordinates: [40.7736, -73.9566]
            },
            {
                id: 'S2-4',
                sequence: 4,
                name: 'Urban Grocers',
                address: '404 Market Square, Food District',
                status: 'pending',
                estimatedTime: '10:45',
                remarks: 'Perishable goods',
                coordinates: [40.7636, -73.9666]
            },
            {
                id: 'S2-5',
                sequence: 5,
                name: 'Fresh Fields Market',
                address: '505 Harvest Lane, Produce Row',
                status: 'pending',
                estimatedTime: '11:15',
                remarks: 'Cold chain delivery',
                coordinates: [40.7536, -73.9766]
            },
            {
                id: 'S2-6',
                sequence: 6,
                name: 'Gourmet Depot',
                address: '606 Culinary Court, Restaurant Row',
                status: 'pending',
                estimatedTime: '11:45',
                remarks: 'Premium products',
                coordinates: [40.7436, -73.9866]
            }
        ]
    },
    {
        id: 'R3',
        routeName: 'Harbor Route',
        currentStop: 'Maritime Logistics',
        lastLocation: 'Port Authority',
        nextLocation: 'Coastal Distributors',
        status: 'exception',
        driverId: 'D3',
        currentLocation: [40.7019, -74.0121],
        stops: [
            {
                id: 'S3-1',
                sequence: 1,
                name: 'Port Authority',
                address: '789 Harbor View, Port District',
                status: 'completed',
                estimatedTime: '09:30',
                actualTime: '09:45',
                remarks: 'Security clearance required',
                coordinates: [40.7099, -74.0201]
            },
            {
                id: 'S3-2',
                sequence: 2,
                name: 'Maritime Logistics',
                address: '456 Dock Street, Waterfront',
                status: 'in-progress',
                estimatedTime: '10:00',
                actualTime: '10:30',
                remarks: 'Heavy equipment delivery',
                coordinates: [40.7019, -74.0121]
            },
            {
                id: 'S3-3',
                sequence: 3,
                name: 'Coastal Distributors',
                address: '123 Seaside Ave, Marina District',
                status: 'pending',
                estimatedTime: '11:00',
                remarks: 'Fork lift required',
                coordinates: [40.6941, -74.0023]
            },
            {
                id: 'S3-4',
                sequence: 4,
                name: 'Ocean Freight Co',
                address: '321 Pier Street, Harbor Front',
                status: 'pending',
                estimatedTime: '11:30',
                remarks: 'Container pickup',
                coordinates: [40.6841, -74.0123]
            },
            {
                id: 'S3-5',
                sequence: 5,
                name: 'Harbor Terminal',
                address: '654 Wharf Road, Shipping District',
                status: 'pending',
                estimatedTime: '12:00',
                remarks: 'Customs inspection',
                coordinates: [40.6741, -74.0223]
            },
            {
                id: 'S3-6',
                sequence: 6,
                name: 'Marine Cargo Express',
                address: '987 Port Way, Container Terminal',
                status: 'pending',
                estimatedTime: '12:30',
                remarks: 'International shipment',
                coordinates: [40.6641, -74.0323]
            }
        ]
    },
    {
        id: 'R4',
        routeName: 'Airport Express',
        currentStop: 'Air Cargo Terminal',
        lastLocation: 'International Freight',
        nextLocation: 'Sky Logistics',
        status: 'on-time',
        driverId: 'D4',
        currentLocation: [40.6413, -73.7781],
        stops: [
            {
                id: 'S4-1',
                sequence: 1,
                name: 'International Freight',
                address: '789 Terminal Road, Cargo Area',
                status: 'completed',
                estimatedTime: '10:00',
                actualTime: '10:00',
                remarks: 'Priority shipment',
                coordinates: [40.6398, -73.7789]
            },
            {
                id: 'S4-2',
                sequence: 2,
                name: 'Air Cargo Terminal',
                address: '456 Airport Plaza, Terminal 4',
                status: 'in-progress',
                estimatedTime: '10:30',
                actualTime: '10:28',
                remarks: 'Customs clearance required',
                coordinates: [40.6413, -73.7781]
            },
            {
                id: 'S4-3',
                sequence: 3,
                name: 'Sky Logistics',
                address: '123 Aviation Drive, Hangar Area',
                status: 'pending',
                estimatedTime: '11:00',
                remarks: 'Special handling required',
                coordinates: [40.6445, -73.7829]
            },
            {
                id: 'S4-4',
                sequence: 4,
                name: 'Express Air Freight',
                address: '321 Runway Road, Cargo Terminal',
                status: 'pending',
                estimatedTime: '11:30',
                remarks: 'Time-sensitive delivery',
                coordinates: [40.6345, -73.7729]
            },
            {
                id: 'S4-5',
                sequence: 5,
                name: 'Global Air Cargo',
                address: '654 Terminal Circle, International Zone',
                status: 'pending',
                estimatedTime: '12:00',
                remarks: 'Oversized cargo',
                coordinates: [40.6245, -73.7629]
            },
            {
                id: 'S4-6',
                sequence: 6,
                name: 'Aero Logistics Hub',
                address: '987 Freight Lane, Cargo City',
                status: 'pending',
                estimatedTime: '12:30',
                remarks: 'High-value shipment',
                coordinates: [40.6145, -73.7529]
            }
        ]
    },
    {
        id: 'R5',
        routeName: 'University Loop',
        currentStop: 'Campus Commons',
        lastLocation: 'Research Center',
        nextLocation: 'Student Union',
        status: 'on-time',
        driverId: 'D5',
        currentLocation: [40.7291, -73.9965],
        stops: [
            {
                id: 'S5-1',
                sequence: 1,
                name: 'Research Center',
                address: '789 Science Way, Lab Complex',
                status: 'completed',
                estimatedTime: '09:15',
                actualTime: '09:15',
                remarks: 'Sensitive equipment',
                coordinates: [40.7282, -73.9959]
            },
            {
                id: 'S5-2',
                sequence: 2,
                name: 'Campus Commons',
                address: '456 University Walk, Main Campus',
                status: 'in-progress',
                estimatedTime: '09:45',
                actualTime: '09:45',
                remarks: 'Multiple drop-off points',
                coordinates: [40.7291, -73.9965]
            },
            {
                id: 'S5-3',
                sequence: 3,
                name: 'Student Union',
                address: '123 College Ave, North Campus',
                status: 'pending',
                estimatedTime: '10:15',
                remarks: 'Loading dock access required',
                coordinates: [40.7304, -73.9977]
            },
            {
                id: 'S5-4',
                sequence: 4,
                name: 'Engineering Building',
                address: '321 Tech Lane, East Campus',
                status: 'pending',
                estimatedTime: '10:45',
                remarks: 'Lab equipment delivery',
                coordinates: [40.7314, -73.9987]
            },
            {
                id: 'S5-5',
                sequence: 5,
                name: 'Medical School',
                address: '654 Health Drive, South Campus',
                status: 'pending',
                estimatedTime: '11:15',
                remarks: 'Temperature sensitive',
                coordinates: [40.7324, -73.9997]
            },
            {
                id: 'S5-6',
                sequence: 6,
                name: 'Sports Complex',
                address: '987 Athletic Circle, West Campus',
                status: 'pending',
                estimatedTime: '11:45',
                remarks: 'Gym equipment',
                coordinates: [40.7334, -74.0007]
            }
        ]
    }
]