import { useState, useReducer, useEffect } from "react";
import "../styles/SearchTable.scss";
import { Table, Form, Dropdown, Input, Pagination, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import DatePicker from "react-datepicker";

function tableReducer(state, action) {
    console.log(state, action)
    switch (action.type) {
        case 'CHANGE_SORT':
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }

            return {
                column: action.column,
                data: _.sortBy(state.data, [action.column]),
                direction: 'ascending',
            }
        default:
            throw new Error()
    }
}

const getCarriers = (tableData) => {
    let outputCarriersArr = [];
    if (tableData != null && tableData.length > 0) {
        let initialCarriersArr = tableData.map(route => route.carrierName)
        let uniqueCarriersArr = initialCarriersArr.filter((value, index, array) => array.indexOf(value) === index);
        uniqueCarriersArr.forEach(carrier => outputCarriersArr.push({ key: carrier, text: carrier, value: carrier }))
    }

    return outputCarriersArr;
}

const SearchParcelTable = (props) => {
    const { tableData, loading } = props
    // console.log(tableData)
    const [carriers, setCarriers] = useState([])
    useEffect(() => setCarriers(getCarriers(tableData)), [tableData]);
    const [state, dispatch] = useReducer(tableReducer, {
        column: null,
        data: tableData,
        direction: null,
    })
    const { column, data, direction } = state;
    const [query, setQuery] = useState("");
    const [date, setDate] = useState(new Date());
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,] = useState(15);

    const setSelected = (e, { value }) => setFilters(value)
    const headerArr = tableData !== null ? tableData.length > 0 && Object.keys(tableData[0]) : [];

    try {
        headerArr.forEach(header => {
            const newHeader = header.replace('_', ' ')
            const arr = newHeader.split(" ")
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const capHeader = arr.join(" ");
            headerArr.push(capHeader)

        });

        const search = (data) => {
            const keys = Object.keys(data[0])
            return data.filter((item) => keys.some(key => item[key].toString().toLowerCase().includes(query.toLowerCase())));
        }

        const style = {
            headerCell: {
                backgroundColor: "#2d8fc4",
                color: "white",
                border: "0.5px solid #ddd",
                fontSize: '1rem',
                textAlign: 'center',
            },
            tCell: {
                fontSize: '1rem',
                textAlign: 'center',
            },
            tCellLink: {
                fontSize: '1rem',
                textAlign: 'center',
                color: '#3b3be4',
                cursor: 'pointer'
            },
        }

        const Headers = () => {
            return (
                Object.keys(tableData[0]).map((col, idx) => {
                    const newHeader = col.replace('_', ' ')
                    const arr = newHeader.split(" ")
                    for (var i = 0; i < arr.length; i++) {
                        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                    }
                    const capHeader = arr.join(" ").replace(/([A-Z])/g, ' $1').trim();
                    return (
                        <Table.HeaderCell
                            key={idx}
                            style={style.headerCell}
                            sorted={column === col ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: col })}
                        >
                            {capHeader}
                        </Table.HeaderCell>
                    )
                })
            )
        }
        const handlePageChange = (e, { activePage }) => {
            setCurrentPage(activePage);
        }
        let currentPageData = search(data).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
        // console.log(currentPageData)
        // console.log(data)
        // console.log(`data's length: ${data.length}`)
        return (
            <div className="app">
                <Form >
                    <Form.Group>
                        <Input icon='search' style={{ width: '400px' }} placeholder='Search...' onChange={e => setQuery(e.target.value)} /> &nbsp;&nbsp;
                        <Dropdown style={{ width: '200px' }} placeholder='All Carriers' fluid multiple selection options={carriers} id="dropdown" onChange={setSelected} />&nbsp;&nbsp;
                        <div id="date-picker" style={{ marginLeft: '5px', width: '390px' }} >
                            {/* <DatePicker selected={date} onChange={(date) => setDate(date)} /> */}
                            <i>Showing orders from last 30 days</i>
                        </div>
                    </Form.Group>
                </Form>
                <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                    {!loading ?
                        <>
                            <Table sortable celled >
                                <Table.Header>
                                    <Table.Row>
                                        <Headers />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {/* {search(data).map((row, idx) => { */}
                                    {currentPageData.map((row, idx) => {
                                        let bgc;
                                        if (row.exceptions) {
                                            if (row.exceptions.includes("Missed")) { bgc = '#C8B6FC' }
                                            else if (row.exceptions.includes("Late")) { bgc = '#FCC2C2' }
                                            else if (row.exceptions.includes("Early")) { bgc = '#F5B642' }
                                            else bgc = '#fff'
                                        }

                                        if (row.carrierName) {
                                            if (filters.length === 0) {
                                                return (
                                                    <Table.Row key={idx} style={{ backgroundColor: bgc, }}>
                                                        {Object.keys(row).map((col, idx) => {
                                                            if (col === 'carrierId') {
                                                                return <Table.Cell key={idx} style={style.tCellLink} onClick={() => props.nextStep(row[col])}> {row[col].toString()}</Table.Cell>
                                                            } else {
                                                                return <Table.Cell key={idx} style={style.tCell}> {row[col].toString()}</Table.Cell>
                                                            }
                                                        })}
                                                    </Table.Row>
                                                )
                                            } else {
                                                return (
                                                    filters.includes(row['carrierName']) &&
                                                    <Table.Row key={idx} style={{ backgroundColor: bgc }}>
                                                        {Object.keys(row).map(col => {
                                                            if (col === 'carrierId') {
                                                                return <Table.Cell style={style.tCellLink} onClick={() => props.nextStep()}> {row[col].toString()}</Table.Cell>
                                                            } else {
                                                                return <Table.Cell style={style.tCell}> {row[col].toString()}</Table.Cell>
                                                            }
                                                        })}
                                                    </Table.Row>
                                                )
                                            }
                                        } else {
                                            return (
                                                <Table.Row key={idx} style={{ backgroundColor: bgc }}>
                                                    {Object.keys(row).map(col => {
                                                        return <Table.Cell style={style.tCell}> {row[col].toString()}</Table.Cell>
                                                    })}
                                                </Table.Row>
                                            )
                                        }
                                    })}
                                </Table.Body>
                            </Table>
                            <Pagination
                                // defaultActivePage={1}
                                activePage={currentPage}
                                // firstItem={null}
                                // lastItem={null}
                                onPageChange={handlePageChange}
                                siblingRange={1}
                                pointing
                                secondary
                                totalPages={Math.ceil(data.length / itemsPerPage)}
                            />
                        </>
                        :
                        <div> <Loader style={{ marginTop: '20px' }} active size='massive'>Loading</Loader></div>
                    }
                    {/* {currentPage === Math.ceil(data.length / itemsPerPage) && <button>Load more...</button>} */}
                </div>
            </div>
        );

    } catch (error) {
        console.log(error)
    }
}

export default SearchParcelTable;