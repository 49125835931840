import React, { useEffect, useState } from 'react'
import {
    Tab, TableRow, TableHeaderCell, TableHeader, TableFooter, TableCell, TableBody, MenuItem, Icon, Label, Menu, Table,
    Button, Select,
    Checkbox,
    Modal,
    ModalHeader,
    ModalContent,
    ModalActions,
    ModalDescription,
    Input,
    Form
} from 'semantic-ui-react'
import { DriverSync } from '../dummyData/DriverSync'
import { RequestPayload } from '../services/ApiClient/types'
import { formatDate, formatTime, formatTimeRaw } from '../utils/datehelper';
import { routeService } from '../services'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DatePicker from "react-datepicker";
const _ = require('lodash');
type Props = {}

const DriverTable = (props: Props) => {
    // const [showStops, setShowStops] = useState(false)
    // const [showStops, setShowStops] = useState(Array(DriverSync.length).fill(false));
    const [showStops, setShowStops] = useState(true);
    const [showManageStops, setShowManageStops] = useState(Array(DriverSync.length).fill(false));
    const [date, setDate] = useState(new Date());
    const [noResponse, setNoResponse] = useState(true)
    const [loading, setLoading] = useState(false)
    const [routeData, setRouteData] = useState(null)
    const [formValues, setFormValues] = useState({ driver: '', route: '', phoneNumber: '', action: false, });
    const [isNotValid, setNotIsValid] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const navigate = useNavigate();

    let { route_id } = useParams();
    console.log('route_id: ', route_id);


    useEffect(() => {
        if (localStorage.token === null || localStorage.token === undefined) {
            const navTo = '/'
            navigate(navTo)
        }
        else {
            localStorage.setItem('route', "DriverSync")
            try {
                let payload: RequestPayload<null> = {
                    queryParams: {
                        pDate: formatDate(date),
                        searchBy: '',
                        searchFor: ''
                    },
                    payload: null
                };

                routeService.GetRouteList(payload)
                    .then(response => {
                        if (response.success) {
                            console.log("routeService.GetRouteList success");
                            if (response.result.length === 0) setNoResponse(true)
                            else {
                                setNoResponse(false)
                                setRouteData(() => response.result);
                                console.log(response.result);
                            }
                            setLoading(false);
                        }
                        else {
                            setLoading(false);
                        }
                    });
            } catch (err) {
                console.log(err)
                setLoading(false);
            }
        }
    }, [date, navigate])

    // const toggleRowVisibility = (index: number) => {
    //     setShowStops(prevState => {
    //         const newState = [...prevState];
    //         newState[index] = !newState[index];
    //         return newState;
    //     });
    // };
    const toggleModal = (e: any) => {
        const { innerText } = e.target
        console.log('innerText ', innerText);
        setOpenModal(true)
    }

    const toggleRowVisibility = () => {
        setShowStops(!showStops)
    }
    const toggleManageRowVisibility = (index: number) => {
        setShowManageStops(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    const handleUpdateForm = (e: any) => {
        const { name, value, checked } = e.target;
        console.log(name, value, checked);
        let newValue = value

        if (name === 'action') {
            newValue = checked; // Set action to a boolean
        }

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
        // Check if all fields are filled and valid (excluding non-string fields)
        // const isFormValid = Object.entries(formValues).every(([key, value]) =>
        //     key === 'action' ? typeof value === 'boolean' : value !== '' && typeof value === 'string'
        // );

        // const isFormValid = Object.values(formValues).every(value =>
        //     value !== '' && typeof value === 'string' || (name === 'action' ? true : false)
        // );
        const isFormValid = Object.values(formValues).every(value =>
            value !== '' && typeof value === 'string' || (name === 'action' ? true : false)
        );
        setNotIsValid(isFormValid);
    };

    // const createDriver = () => {
    //     setOpenCreate(true);
    // }
    const statusOpts: any = [
        { key: 'on-time', value: 'on-time', text: 'on-time' },
        { key: 'late', value: 'late', text: 'late' },
        { key: 'exception', value: 'exception', text: 'exception' }
    ]
    const routeOpts: any = [
        { key: 'completed', value: 'completed', text: 'completed' },
        { key: 'in-progress', value: 'in-progress', text: 'In Progress' },
        { key: 'pending', value: 'pending', text: 'pending' }
    ]
    const driverDummyData: any = [
        {
            Driver: "John Doe",
            Route: "123 Elm St, Springfield, IL",
            PhoneNumber: 5551234,
            Action: true
        },
        {
            Driver: "Jane Smith",
            Route: "456 Oak St, Springfield, IL",
            PhoneNumber: 5555678,
            Action: false
        },
        {
            Driver: "Sam Johnson",
            Route: "789 Pine St, Springfield, IL",
            PhoneNumber: 5552345,
            Action: true
        }
    ];
    const groupedCouriers = _.groupBy(routeData, 'courier')
    console.log(groupedCouriers);
    const panes = [
        // Today's
        {
            menuItem: { key: 'Today', icon: 'certificate', content: 'Today' }, render: () =>
            (
                <Tab.Pane>
                    {/* <DatePicker selected={date} onChange={(date) => setDate(date)} /> */}
                    <Table color='blue'>
                        <TableHeader>
                            <TableRow textAlign='center'>
                                <TableHeaderCell>Route Name</TableHeaderCell>
                                {/* <TableHeaderCell>Driver</TableHeaderCell> */}
                                {/* <TableHeaderCell>Current Stop</TableHeaderCell> */}
                                {/* <TableHeaderCell>Last Location</TableHeaderCell> */}
                                {/* <TableHeaderCell>Next Location</TableHeaderCell> */}
                                <TableHeaderCell>Status</TableHeaderCell>
                                <TableHeaderCell>Action</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {/* map through mock data with table drop downs*/}
                            {/* {DriverSync.map((sync: any, index: number) => {
                                // console.log(sync.stops);
                                return (
                                    <>
                                        <TableRow textAlign='center'>
                                            <TableCell><Icon name='chevron right' onClick={() => toggleRowVisibility(index)} />{sync.routeName}</TableCell>
                                            <TableCell><Icon name='users' /></TableCell>
                                            <TableCell>{sync.currentStop}</TableCell>
                                            <TableCell>{sync.lastLocation}</TableCell>
                                            <TableCell>{sync.nextLocation}</TableCell>
                                            <TableCell>{sync.status}</TableCell>
                                            <TableCell><Icon name='comment' /><Icon name='map pin' /></TableCell>
                                        </TableRow>
                                        {showStops[index] &&
                                            (
                                                <>
                                                    <TableRow>
                                                        <TableHeader>
                                                            <TableHeaderCell>Route Stops</TableHeaderCell>
                                                        </TableHeader>
                                                    </TableRow>
                                                    {sync.stops.map((stop: any) => {
                                                        return (
                                                            <TableRow textAlign='center'>
                                                                <TableCell>{stop.name}</TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell><i>Est: {stop.estimatedTime} {stop.actualTime ? `Actual: ${stop.actualTime}` : null}</i></TableCell>
                                                                <TableCell>{stop.status}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }

                                    </>
                                )
                            })} */}
                            <TableRow>
                                <TableCell>
                                    {routeData !== null ?
                                        Object.entries(groupedCouriers).map(([carrier, ArryOfroutes]: any, index: number) => {
                                            return (
                                                <>
                                                    <TableRow textAlign='center'>
                                                        {/* <TableCell><Icon name='chevron right' onClick={() => toggleRowVisibility(index)} />{carrier}</TableCell> */}
                                                        <TableCell><Icon name='chevron right' onClick={() => toggleRowVisibility()} />{carrier}</TableCell>
                                                    </TableRow>
                                                    {showStops && (
                                                        <>
                                                            <TableRow>
                                                                <TableHeader>
                                                                    <TableHeaderCell>Route Stops</TableHeaderCell>
                                                                </TableHeader>
                                                            </TableRow>
                                                            {/* routeObj is an array of objects */}
                                                            {ArryOfroutes.map((routeObj: any) => {
                                                                return (
                                                                    <TableRow textAlign='center'>
                                                                        {Object.entries(routeObj).map(([key, value]: any) => {
                                                                            if (key === 'description') {
                                                                                return (
                                                                                    <>
                                                                                        <TableCell value={value} onClick={(e: any) => toggleModal(e)}>{value}</TableCell>
                                                                                    </>
                                                                                )
                                                                            } else if (key === 'start_time') {
                                                                                return (
                                                                                    <>
                                                                                        <TableCell></TableCell>
                                                                                        <TableCell></TableCell>
                                                                                        <TableCell><i>Start Time: {formatTimeRaw(value)}</i></TableCell>
                                                                                    </>
                                                                                )
                                                                            } else if (key === 'end_time') {
                                                                                return (
                                                                                    <>
                                                                                        <TableCell><i>End Time: {formatTimeRaw(value)}</i></TableCell>
                                                                                    </>
                                                                                )
                                                                            } else if (key === 'completed') {
                                                                                return (
                                                                                    <TableCell><i>Completed:</i><Checkbox checked={value} /></TableCell>
                                                                                )
                                                                            }
                                                                            // return (
                                                                            //     <TableRow>
                                                                            //         <TableCell>{key}</TableCell>
                                                                            //         <TableCell>{value}</TableCell>
                                                                            //     </TableRow>
                                                                            // )
                                                                        })}
                                                                    </TableRow>
                                                                )
                                                            })}

                                                        </>
                                                    )}
                                                </>
                                            )
                                        }
                                        )
                                        :
                                        'No Routes Today'}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Modal
                        onClose={() => setOpenModal(false)}
                        onOpen={() => setOpenModal(true)}
                        open={openModal}
                        size='large'
                    >
                        <ModalHeader>Add A Driver</ModalHeader>
                        <ModalContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p>Test</p>
                        </ModalContent>
                        <ModalActions>

                        </ModalActions>
                    </Modal>
                </Tab.Pane>
            )
        },
        //Manage
        {
            menuItem: { key: 'Manage', icon: 'chart bar outline', content: 'Manage' }, render: () =>
            (
                <Tab.Pane>
                    <DatePicker selected={date} onChange={(date) => setDate(date)} />

                    <Table color='blue'>
                        <TableHeader>
                            <TableRow textAlign='center'>
                                <TableHeaderCell>Route Management</TableHeaderCell>
                                <TableHeaderCell><Button color='blue'>Add Route</Button></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {DriverSync.map((sync: any, index: number) => {
                                // console.log(sync.stops);
                                return (
                                    <>
                                        <TableRow textAlign='center'>
                                            <TableCell><Icon name='chevron right' onClick={() => toggleManageRowVisibility(index)} />{sync.routeName}</TableCell>
                                            <TableCell><Select value={sync.status} options={statusOpts} /></TableCell>
                                            <TableCell>{`D${index + 1}`}</TableCell>
                                            <TableCell><Button>Expand</Button></TableCell>
                                            <TableCell><Icon name='trash' /></TableCell>
                                        </TableRow>
                                        {showManageStops[index] &&
                                            (
                                                <>
                                                    <TableRow>
                                                        <TableHeader>
                                                            <TableHeaderCell><Button><Icon name='plus' />Add Stop</Button></TableHeaderCell>
                                                        </TableHeader>
                                                    </TableRow>
                                                    {sync.stops.map((stop: any) => {
                                                        return (
                                                            <TableRow textAlign='center'>
                                                                <TableCell>{stop.name}</TableCell>
                                                                <TableCell>{stop.estimatedTime}</TableCell>
                                                                <TableCell><Select value={stop.status} options={routeOpts} /></TableCell>
                                                                <TableCell><Checkbox label='Will Call' /></TableCell>
                                                                <TableCell><Icon name='trash' /></TableCell>

                                                            </TableRow>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }

                                    </>
                                )
                            })}
                            <TableRow>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Tab.Pane>
            )
        },
        //Drivers
        {
            menuItem: { key: 'Drivers', icon: 'address book', content: 'Drivers' }, render: () =>
            (
                <Tab.Pane>
                    <Form style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginLeft: '70rem' }}>
                        <Input name='driver' value={formValues.driver} onChange={(e: any) => handleUpdateForm(e)} placeholder='Name' />
                        <Input name='route' value={formValues.route} onChange={(e: any) => handleUpdateForm(e)} placeholder='Route' />
                        <Input name='phoneNumber' value={formValues.phoneNumber} onChange={(e: any) => handleUpdateForm(e)} placeholder='Phone Number' />
                        <Input type='checkbox' name='action' checked={formValues.action} onChange={(e: any) => handleUpdateForm(e)} label='Action' />
                        <Button disabled={!isNotValid} style={{ marginBottom: '1rem', marginTop: '1rem' }} color='blue'>Add Driver</Button>
                    </Form>
                    <Table color='blue'>
                        <TableHeader>
                            <TableRow textAlign='center'>
                                <TableHeaderCell>Driver</TableHeaderCell>
                                <TableHeaderCell>Route</TableHeaderCell>
                                <TableHeaderCell>Phone Number</TableHeaderCell>
                                <TableHeaderCell>Actions</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {driverDummyData.map((driverObj: any) => {
                                return (
                                    <TableRow textAlign='center'>
                                        {Object.entries(driverObj).map(([key, value]: any) => {
                                            if (key === 'Action') {
                                                return (
                                                    <TableCell><Checkbox checked={value} /></TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell>{value}</TableCell>
                                                )
                                            }
                                        })}

                                    </TableRow>

                                )
                            })}
                        </TableBody>
                    </Table>
                    {/* <Modal
                        onClose={() => setOpenCreate(false)}
                        onOpen={() => setOpenCreate(true)}
                        open={openCreate}
                        size='large'
                    >
                        <ModalHeader>Add A Driver</ModalHeader>
                        <ModalContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Input placeholder='Name...' />
                            <Input placeholder='Route...' />
                            <Input placeholder='Phone Number...' />
                            <Input placeholder='Actions' />
                        </ModalContent>
                        <ModalActions>
                            <Button color='black' onClick={() => setOpenCreate(false)}>
                                Nope
                            </Button>
                            <Button
                                content="Yep, that's me"
                                labelPosition='right'
                                icon='checkmark'
                                onClick={() => setOpenCreate(false)}
                                positive
                            />
                        </ModalActions>
                    </Modal> */}
                </Tab.Pane>
            )
        }
    ]

    return (
        <div style={{ margin: '0px 20px 0px 20px' }}>
            <Tab panes={panes} />
        </div>
    )
}

export default DriverTable